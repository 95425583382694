// Generated by Framer (f26e712)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-Sh6N4"

const variantClassNames = {rZdpGCN8f: "framer-v-1vv2cig"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, uJ6xHDeRZ: title ?? props.uJ6xHDeRZ ?? "Competence"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, uJ6xHDeRZ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "rZdpGCN8f", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1vv2cig", className, classNames)} data-border data-framer-name={"Tag"} layoutDependency={layoutDependency} layoutId={"rZdpGCN8f"} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-575a767c-e7c9-474e-b7cf-cfd3e4469399, rgb(44, 44, 44))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-175e9f7e-9cf9-42cd-b522-52506cd7d635, rgb(28, 28, 28))", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-line-height": "1.85em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246)))"}}>Competence</motion.p></React.Fragment>} className={"framer-11hg75d"} data-framer-name={"Competence"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"rui3prpQb"} style={{"--extracted-r6o4lv": "var(--token-72be4b58-ad30-40d4-9403-e97ffbb2d1b3, rgb(246, 246, 246))"}} text={uJ6xHDeRZ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Sh6N4.framer-1j6fyah, .framer-Sh6N4 .framer-1j6fyah { display: block; }", ".framer-Sh6N4.framer-1vv2cig { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 12px 2px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-Sh6N4 .framer-11hg75d { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Sh6N4.framer-1vv2cig { gap: 0px; } .framer-Sh6N4.framer-1vv2cig > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Sh6N4.framer-1vv2cig > :first-child { margin-left: 0px; } .framer-Sh6N4.framer-1vv2cig > :last-child { margin-right: 0px; } }", ".framer-Sh6N4[data-border=\"true\"]::after, .framer-Sh6N4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 97
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"uJ6xHDeRZ":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameriG_0hVeYF: React.ComponentType<Props> = withCSS(Component, css, "framer-Sh6N4") as typeof Component;
export default FrameriG_0hVeYF;

FrameriG_0hVeYF.displayName = "Partner label tag";

FrameriG_0hVeYF.defaultProps = {height: 26, width: 97};

addPropertyControls(FrameriG_0hVeYF, {uJ6xHDeRZ: {defaultValue: "Competence", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameriG_0hVeYF, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})